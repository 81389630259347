import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { loadCSS } from 'fg-loadcss'
import * as loadScript from 'simple-load-script'
import 'babel-polyfill' // must be imported for async/await to work, see e.g. https:/github.com/gatsbyjs/gatsby/pull/3369#issuecomment-354599985

import CssBaseline from '@material-ui/core/CssBaseline'

class Layout extends React.Component {
  componentDidMount() {
    // load the two JS libraries into `body`
    /*
    await loadScript('/js/jquery-3.3.1.min.js', { inBody: true })
    await loadScript('/js/owl.carousel.min.js', { inBody: true })
    await loadScript('/js/script.js', { inBody: true })
*/
    /*
    await loadScript('/js/bootstrap.min.js', { inBody: true })
    await loadScript('/js/lightbox.min.js', { inBody: true })
    await loadScript('/js/lity.min.js', { inBody: true })

    await loadScript('/js/script.js', { inBody: true })
    */
    loadCSS(
      'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css'
    )
    loadCSS(
      'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css'
    )
    loadCSS('/css/fontastic.css')
  }

  render() {
    const { children } = this.props
    return (
      <>
        <CssBaseline />
        <Helmet
          title="Innosmile"
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'charset', content: 'UTF-8' },
            {
              name: 'viewport',
              content: 'width=device-width, initial-scale=1, shrink-to-fit=no'
            }
          ]}
        >
          <html lang="fr" />
          <link rel="preconnect" href="https://maps.gstatic.com" />
        </Helmet>
        {children}
        <div className="scroll-top">
          <span className="animated fadeIn">
            <i className="fas fa-chevron-up" />
          </span>
        </div>
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
