module.exports = {
  ComponentIndex: {
    id: 'ComponentIndex',
    namespace: 'home',
    path: {
      fr: '/',
      en: '/en/'
    }
  },
  ComponentBlog: {
    id: 'ComponentBlog',
    namespace: 'home',
    path: {
      fr: '/blog',
      en: '/en/blog'
    }
  },
  ComponentTeam: {
    id: 'ComponentTeam',
    namespace: 'home',
    path: {
      fr: '/l-equipe',
      en: '/en/team'
    }
  }
}
