import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'
import { StyleSheet, css } from 'aphrodite'
import chroma from 'chroma-js'
import { compose } from 'recompose'

import { Link as ScrollLink } from 'react-scroll'
import cn from 'classnames'
import Config from '../../Config'

import Link from '../Link'
import pagesInfos from '../../config/i18n/pagesInfos'
import ScrollPosition from '../utils/ScrollPosition'
import Languages, { getLanguage } from './Languages'

const offset = -48

const styles = StyleSheet.create({
  active: {
    color: Config.colors.primary
  },
  link: {
    cursor: 'pointer',
    ':hover': {
      color: Config.colors.primary
    }
  },
  navbarCollapse: {
    display: 'flex',
    width: 'auto',
    justifyContent: 'flex-end'
  }
})

const TeamLinkComponent = link => (
  <li>
    <Link
      page={pagesInfos.ComponentTeam}
      className={cn('click-close', css(styles.link))}
      query={`#${link.name}`}
    >
      {link.label}
    </Link>
  </li>
)

const LinkComponent = link => (
  <li>
    <Link
      page={pagesInfos.ComponentIndex}
      className={cn('click-close', css(styles.link))}
      query={`#${link.name}`}
    >
      {link.label}
    </Link>
  </li>
)

const ScrollLinkComponent = link => (
  <li>
    <ScrollLink
      to={link.name}
      className={cn('click-close', css(styles.link))}
      spy
      activeClass={css(styles.active)}
      smooth
      offset={offset}
    >
      {link.label}
    </ScrollLink>
  </li>
)
class Menu extends Component {
  state = {
    open: false
  }
  onToggleMenu = () => {
    this.setState({
      open: !this.state.open
    })
    this.setState({
      open: !this.state.open
    })
  }

  componentDidMount() {}

  render() {
    const { isDisplayFromHome } = this.props

    const AgenceLink = isDisplayFromHome
      ? ScrollLinkComponent({
          name: 'agence',
          label: "L'agence"
        })
      : LinkComponent({
          name: 'agence',
          label: "L'agence"
        })
    const ServicesLink = isDisplayFromHome
      ? ScrollLinkComponent({
          name: 'services',
          label: 'Services'
        })
      : LinkComponent({
          name: 'services',
          label: 'Services'
        })
    const ReferencesLink = isDisplayFromHome
      ? ScrollLinkComponent({
          name: 'references',
          label: 'Références'
        })
      : LinkComponent({
          name: 'references',
          label: 'Références'
        })
    const BlogLink = isDisplayFromHome
      ? ScrollLinkComponent({
          name: 'blog',
          label: 'Blog'
        })
      : LinkComponent({
          name: 'blog',
          label: 'Blog'
        })
    const EquipeLink = TeamLinkComponent({
      name: 'equipe',
      label: "L'équipe"
    })
    const ContactLink = isDisplayFromHome
      ? ScrollLinkComponent({
          name: 'contact-us',
          label: 'Contact'
        })
      : LinkComponent({
          name: 'contact-us',
          label: 'Contact'
        })

    return (
      <nav
        className={cn(
          'navbar',
          'navbar-default',
          'navbar-fixed-top',
          this.props.scrollTop > 50 && 'top-nav'
        )}
      >
        <div className="container">
          <div className="navbar-header">
            <div
              className={cn(
                'navbar-toggle',
                'collapsed',
                'menu-icon',
                this.state.open && 'change'
              )}
              role="button"
              onClick={this.onToggleMenu}
            >
              <div className="bar half start" />
              <div className="bar" />
              <div className="bar half end" />
            </div>
            <div className="logo">
              <Link page={pagesInfos.ComponentIndex}>
                <img
                  className="navbar-brand"
                  src={
                    this.props.scrollTop < 50
                      ? '/img/logo/logo-blanc.png'
                      : '/img/logo/logo.png'
                  }
                  alt="logo"
                />
              </Link>
            </div>
          </div>
          <div
            className={cn(
              'navbar-collapse',
              !this.state.open && css(styles.navbarCollapse),
              this.state.open && 'in'
            )}
            id="menu"
            aria-expanded={this.state.open.toString()}
            data-hover="dropdown"
            data-animations="fadeInUp"
          >
            <ul className="nav navbar-nav navbar-right">
              {AgenceLink}
              {ServicesLink}
              {ReferencesLink}
              {ContactLink}
              <li>
                <a className="click-close free-try" href="#">
                  0477 872 806
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}
/*
   <Languages />*/
const enhance = compose(
  withNamespaces('home'),
  ScrollPosition
)

Menu.propTypes = {
  isDisplayFromHome: PropTypes.bool
}

Menu.defaultProps = {
  isDisplayFromHome: true
}

export default enhance(Menu)
