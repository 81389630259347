import React from 'react'

import { StyleSheet, css } from 'aphrodite'
import Button from '@material-ui/core/Button'

import Link from '../Link'
import pagesInfos from '../../config/i18n/pagesInfos'
import i18next from '../../config/i18n/context'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    borderRadius: '50%',
    border: 'solid 2px #eee'
  },
  button: {
    padding: 0,
    width: '36px',
    lineHeight: '36px',
    height: '36px',
    minHeight: '36px',
    minWidth: '36px',
    borderRadius: '50%'
  }
})

export const getLanguage = () =>
  i18next.language ||
  (typeof window !== 'undefined' && window.localStorage.i18nextLng)

export const getLocale = () =>
  (
    i18next.language ||
    (typeof window !== 'undefined' && window.localStorage.i18nextLng)
  ).replace('en', 'en-US')

const Languages = ({ page }) => {
  const lang = getLanguage()
  console.log('current lang : ' + lang)
  return (
    <div className={css(styles.container)}>
      {lang !== 'fr' && (
        <Link
          page={page ? pagesInfos[page] : pagesInfos.ComponentIndex}
          lang="fr"
        >
          <Button className={css(styles.button)}>fr</Button>
        </Link>
      )}
      {lang !== 'en' && (
        <Link
          page={page ? pagesInfos[page] : pagesInfos.ComponentIndex}
          lang="en"
        >
          <Button className={css(styles.button)}>en</Button>
        </Link>
      )}
    </div>
  )
}

export default Languages
