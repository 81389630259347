import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Scroll from 'react-scroll'
import { StyleSheet, css } from 'aphrodite'
import { withNamespaces } from 'react-i18next'
import { StaticQuery, graphql, Link } from 'gatsby'
import LoadableVisibility from 'react-loadable-visibility/react-loadable'
import Loadable from 'react-loadable'

import cn from 'classnames'

import Config from '../../Config'
import Classes from '../../styles/classes'

const Loading = () => <div style={{ minHeight: '1000px' }} />

const Contact = LoadableVisibility({
  loader: () => import('./Contact'),
  loading: Loading
})
const Map = Loadable({
  loader: () => import('./Map'),
  loading: Loading,
  delay: 1
})

const styles = StyleSheet.create({
  footer: {
    backgroundColor: '#002337'
  },
  copy: {
    backgroundColor: '#002337'
  },
  link: {
    color: 'inherit'
  }
})

const ServicesComponent = ({ service, produits }) => {
  const Produits = produits.map(produit => (
    <li key={produit.id}>
      <Link to={produit.permalink} title={produit.titre}>
        {produit.titre}
      </Link>
    </li>
  ))
  return (
    <div className="footer-about col-lg-3 col-md-3 col-sm-3 col-xs-12">
      <div className="footer-title">
        <h3>
          <Link
            to={service.permalink}
            title={service.titre}
            className={css(styles.link)}
          >
            {service.titre}
          </Link>
        </h3>
      </div>

      <div className="footer-link">
        <ul>{Produits}</ul>
      </div>
    </div>
  )
}

const Footer = ({ map, contact }) => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        services: allContentfulServices(
          filter: { node_locale: { eq: "fr" } }
          sort: { fields: [position], order: ASC }
        ) {
          edges {
            node {
              id
              titre
              permalink
              position
              description {
                description
              }
            }
          }
        }
        produits: allContentfulProduits(
          filter: { node_locale: { eq: "fr" } }
          sort: { fields: [position], order: ASC }
        ) {
          edges {
            node {
              id
              titre
              permalink
              position
              description {
                description
              }
              service {
                id
              }
            }
          }
        }
      }
    `}
    render={data => {
      const Services = data.services.edges.map(({ node }) => {
        const produits = data.produits.edges
          .filter(edge => edge.node.service.id === node.id)
          .map(edge => edge.node)
        return (
          <ServicesComponent service={node} produits={produits} key={node.id} />
        )
      })

      return (
        <>
          <Scroll.Element id="contact-us" name="contact-us">
            {map && (
              <div
                className="container"
                style={{ position: 'relative', height: '400px' }}
              >
                <Map />
              </div>
            )}
            {contact && <Contact />}
          </Scroll.Element>
          <footer className={css(styles.footer)}>
            <div className="container">
              <div className="row">
                <div className="anfang col-lg-3 col-md-3 col-sm-3 col-xs-12">
                  <div className="footer-img">
                    <img src="/img/logo/logo-blanc.png" alt="logo" />
                  </div>
                  <div className="footer-text">
                    <p>Votre agence de communication digitale</p>
                  </div>
                  <div className="social">
                    <ul>
                      <li>
                        <a href="">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <i className="fab fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {Services}
              </div>
            </div>
            <div className={cn('copy', css(styles.copy))}>
              <div className="container">
                <p>Copyright © 2018 All Rights Reserved / Innosmile</p>
              </div>
            </div>
          </footer>
        </>
      )
    }}
  />
)

Footer.propTypes = {
  map: PropTypes.bool,
  contact: PropTypes.bool
}

Footer.defaultProps = {
  map: false,
  contact: false
}

export default withNamespaces('home')(Footer)
